import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MenuIcon from "@material-ui/icons/Menu";

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
      >
        <MenuIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component="a" onClick={handleClose} href="./dashboard">
          Dashboard
        </MenuItem>
        <MenuItem component="a" onClick={handleClose} href="./contact">
          Contact
        </MenuItem>
        <MenuItem
          component="a"
          onClick={() => {
            props.handleAuth.signout(() => {
              window.location.reload();
            });
          }}
        >
          Signout
        </MenuItem>
      </Menu>
    </div>
  );
}
