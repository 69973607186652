const config = {
  dev: {
    cognito: {
      USER_POOL_ID:
        process.env.REACT_APP_COGNITO_POOL_ID || "us-east-2_yD5dcBiV7",
      APP_CLIENT_ID:
        process.env.REACT_APP_COGNITO_CLIENT_ID || "e0p5q7ef4b2jgirpja657hkdu",
    },
    api: {
      USER: "https://api.dev.thinkhomewise.com/partner/user",
      LOGO_UPLOAD: "https://api.dev.thinkhomewise.com/partner/logo",
      PARTNER: "https://api.dev.thinkhomewise.com/partner/brand",
    },
    google: {
      CAPTCHA: "6Lf5QtQUAAAAABY8kRzWmZcg1s8lGiceF_rr58kg",
      MAPS: "AIzaSyB3EqvHQeqxqCivcrWGGfvHA3WSti2IBus",
      GTM_ID: "GTM-NHW8TW8",
      GTM_AUTH: "zZCjWIGu_zpl1i-sFcpZ9g",
      GTM_PREV: "env-3",
    },
  },
  staging: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    api: {
      USER: "https://api.staging.thinkhomewise.com/partner/user",
      LOGO_UPLOAD: "https://api.staging.thinkhomewise.com/partner/logo",
      PARTNER: "https://api.staging.thinkhomewise.com/partner/brand",
    },
    google: {
      CAPTCHA: "6Lf5QtQUAAAAABY8kRzWmZcg1s8lGiceF_rr58kg",
      MAPS: "AIzaSyBFp1vatb_la3ZjzJrFNaDD15M4SG2Ser0",
      GTM_ID: "GTM-NHW8TW8",
      GTM_AUTH: "qFf2cpU4ts8_T0ME1YAaFQ",
      GTM_PREV: "env-4",
    },
  },
  prod: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    api: {
      USER: "https://api.thinkhomewise.com/partner/user",
      LOGO_UPLOAD: "https://api.thinkhomewise.com/partner/logo",
      PARTNER: "https://api.thinkhomewise.com/partner/brand",
    },
    google: {
      CAPTCHA: "6Lf5QtQUAAAAABY8kRzWmZcg1s8lGiceF_rr58kg",
      MAPS: "AIzaSyAkONImmXLu9vUg4bKP2yj5g96Z6L7E0Ns",
      GTM_ID: "GTM-NHW8TW8",
      GTM_AUTH: "FSeDWhwAwbJky2y-rQMWAA",
      GTM_PREV: "env-1",
    },
  },
};

export default {
  // Add common config values here
  ...config[process.env.REACT_APP_STAGE],
};
