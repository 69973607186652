import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";

import landing_en from "./translations/en/landing.json";
import landing_fr from "./translations/fr/landing.json";

import coLanding_en from "./translations/en/coLanding.json";
import coLanding_fr from "./translations/fr/coLanding.json";

import dashboard_en from "./translations/en/dashboard.json";
import dashboard_fr from "./translations/fr/dashboard.json";

import terms_en from "./translations/en/terms.json";
import terms_fr from "./translations/fr/terms.json";

import authErrors_en from "./translations/en/authErrors.json";
import authErrors_fr from "./translations/fr/authErrors.json";

import profile_en from "./translations/en/profile.json";
import profile_fr from "./translations/fr/profile.json";

import status_en from "./translations/en/status.json";
import status_fr from "./translations/fr/status.json";

// the translations
const resources = {
  en: {
    common: common_en,
    landing: landing_en,
    coLanding: coLanding_en,
    dashboard: dashboard_en,
    terms: terms_en,
    authErrors: authErrors_en,
    profile: profile_en,
    status: status_en
  },
  fr: {
    common: common_fr,
    landing: landing_fr,
    coLanding: coLanding_fr,
    dashboard: dashboard_fr,
    terms: terms_fr,
    authErrors: authErrors_fr,
    profile: profile_fr,
    status: status_fr
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    languages: ["en", "fr"],
    ns: ["common"],
    defaultNS: "common",
    resources,
    lng: "en",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
