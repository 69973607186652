import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import Loading from '../pages/notfound/Loading';
import Config from '../config';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: Config.google.GTM_ID,
  auth: Config.google.GTM_AUTH,
  preview: Config.google.GTM_PREV,
};

TagManager.initialize(tagManagerArgs);

// Stand alone pages
const Landing = lazy(() => import('../pages/landing/Landing'));
const Terms = lazy(() => import('../pages/terms/Terms'));
const Contact = lazy(() => import('../pages/contact/Contact'));
const BookDemo = lazy(() => import('../pages/demo/BookDemo'));
const Privacy = lazy(() => import('../pages/privacy/Privacy'));

// Auth pages
const Join = lazy(() => import('../pages/auth/Join'));
const SignIn = lazy(() => import('../pages/auth/SignIn'));
const Verify = lazy(() => import('../pages/auth/Verify'));
const PasswordReset = lazy(() => import('../pages/auth/PasswordReset'));

// Client Pages
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const Profile = lazy(() => import('../pages/dashboard/Profile'));
const CoLanding = lazy(() => import('../pages/dashboard/CoLanding'));

// Else
// const NotFound = lazy(() => import("../pages/notfound/NotFound"));

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const poolData = {
  UserPoolId: Config.cognito.USER_POOL_ID,
  ClientId: Config.cognito.APP_CLIENT_ID,
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

const handleAuth = {
  isAuthenticated: false,
  authId: '',
  getSession() {
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          // alert(err.message || JSON.stringify(err));
          handleAuth.isAuthenticated = false;
          return false;
        }

        handleAuth.isAuthenticated = true;
        handleAuth.authId = session.idToken.payload.sub;
        handleAuth.jwtToken = session.idToken.jwtToken;
        return true;
      });
    } else {
      // cognito user is null. do nothing.
    }
  },
  verifyCode(email, userCode, password, component, cb) {
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(userCode, true, (err, result) => {
      if (err) {
        const errors = {
          codeError: '',
          codeInvalid: false,
        };

        if (err.code === 'CodeMismatchException') {
          errors.codeError = err.message;
          errors.codeInvalid = true;
          component.setState({
            ...component.state,
            ...errors,
          });
        }
        return;
      }

      if (password) {
        handleAuth.authenticate(email, password, component, cb);
        return;
      }

      cb();
    });
  },
  resendCode: (email, component, cb) => {
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        // alert(err.message || JSON.stringify(err));
        const errors = {
          emailError: '',
          emailInvalid: false,
          verified: true,
        };

        errors.emailError = err.message;
        errors.emailInvalid = true;
        component.setState({
          ...component.state,
          ...errors,
        });

        return;
      }

      cb();
    });
  },
  resetPassword: (email, component, cb) => {
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
      onSuccess: function (result) {},
      onFailure: function (err) {
        const errors = {
          emailError: '',
          emailInvalid: false,
        };
        // alert(err.message || JSON.stringify(err));

        if (
          err.code === 'LimitExceededException' ||
          err.code === 'UserNotFoundException' ||
          err.code === 'InvalidParameterException'
        ) {
          errors.emailError = err.message;
          errors.emailInvalid = true;
          component.setState({
            ...component.state,
            ...errors,
          });
        }
      },
      inputVerificationCode() {
        // var verificationCode = prompt("Please input verification code ", "");
        // var newPassword = prompt("Enter new password ", "");
        // cognitoUser.confirmPassword(verificationCode, newPassword, this);
        cb();
      },
    });
  },
  confirmPassword: (email, verificationCode, newPassword, component, cb) => {
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise(() => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          const errors = {
            passwordError: '',
            passwordInvalid: false,
            codeError: '',
            codeInvalid: false,
          };
          if (
            err.code === 'InvalidPasswordException' ||
            err.code === 'UserNotFoundException'
          ) {
            errors.passwordError = err.message;
            errors.passwordInvalid = true;
            component.setState({
              ...component.state,
              ...errors,
            });
          }
          if (err.code === 'CodeMismatchException') {
            errors.codeError = err.message;
            errors.codeInvalid = true;
            component.setState({
              ...component.state,
              ...errors,
            });
          }
          if (err.code === 'InvalidParameterException') {
            errors.codeError = '';
            errors.codeInvalid = true;
            errors.passwordError = err.message;
            errors.passwordInvalid = true;
            component.setState({
              ...component.state,
              ...errors,
            });
          }
        },
        onSuccess() {
          handleAuth.authenticate(email, newPassword, component, cb);
          return;
        },
      });
    });
  },
  authenticate: (email, password, component, cb) => {
    console.log('made it here');
    var authenticationData = {
      Username: email,
      Password: password,
    };
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );

    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH'); // 2020.04.18 - temporary flow for migration
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('success');
        handleAuth.isAuthenticated = true;
        handleAuth.authID = result.idToken.payload.sub;

        cb();
      },

      onFailure: (err) => {
        // alert(err.message || JSON.stringify(err));
        console.log('fail', err);

        const errors = {
          emailError: '',
          emailInvalid: false,
          passwordError: '',
          passwordInvalid: false,
          verified: true,
        };

        if (err.code === 'UserNotConfirmedException') {
          errors.emailError =
            'The account with given email address has not been verified.';
          errors.emailInvalid = true;
          errors.verified = false;
          component.setState({
            ...component.state,
            ...errors,
          });
        }

        if (err.code === 'UserNotFoundException') {
          errors.emailError =
            'We have no record of an account with the given email address.';
          errors.emailInvalid = true;
          component.setState({
            ...component.state,
            ...errors,
          });
        }

        if (err.code === 'NotAuthorizedException') {
          errors.emailError = '';
          errors.emailInvalid = true;
          errors.passwordError = err.message;
          errors.passwordInvalid = true;
          component.setState({
            ...component.state,
            ...errors,
          });
        }

        if (err.code === 'ResourceNotFoundException') {
          errors.masterError =
            "We're Sorry, please refresh the page and try again.";
          errors.masterInvalid = true;
          component.setState({
            ...component.state,
            ...errors,
          });
        }
      },
    });
  },
  signout(cb) {
    var cognitoUser = userPool.getCurrentUser();
    handleAuth.isAuthenticated = false;
    cognitoUser.signOut();

    setTimeout(cb, 100);
  },
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        handleAuth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign_in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// const possibleAdvisorRoutes = [
//   "vince",
//   "jesse",
//   "carlos",
//   "bjblayney-nOmEHKLK8J"
// ];

// function CoBrandRoute({ children, ...rest }) {
//   const redirectBase =
//     process.env.REACT_APP_STAGE === "prod" ? "my" : "preview-app";
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         possibleAdvisorRoutes.includes(location.pathname) ? (
//           (window.location.href =
//             `https://${redirectBase}.thinkhomewise.com/advisor` +
//             location.pathname)
//         ) : (
//           <NotFound
//             handleAuth={handleAuth}
//             history={useHistory}
//             location={useLocation}
//           />
//         )
//       }
//     />
//   );
// }

export default function AuthRouter() {
  handleAuth.getSession();
  return (
    <Router>
      <Suspense
        fallback={
          <Loading
            handleAuth={handleAuth}
            history={useHistory}
            location={useLocation}
          />
        }
      >
        <Switch>
          <Route exact path="/">
            <Landing
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/join/">
            <Join
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/sign_in/">
            <SignIn
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/verify/">
            <Verify
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/password_reset/">
            <PasswordReset
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/terms/">
            <Terms
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/contact/">
            <Contact
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/book_demo/">
            <BookDemo
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>
          <Route path="/privacy/">
            <Privacy
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </Route>

          <PrivateRoute path="/dashboard/">
            <Dashboard
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </PrivateRoute>
          <PrivateRoute path="/profile/">
            <Profile
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </PrivateRoute>
          <PrivateRoute path="/co-landing/">
            <CoLanding
              handleAuth={handleAuth}
              history={useHistory}
              location={useLocation}
            />
          </PrivateRoute>

          {/* <Route render={props => <CoBrandRoute {...props} />} /> */}

          <Route
            path="/:username"
            exact
            component={({ match }) => {
              return (
                <CoLanding
                  match={match}
                  handleAuth={handleAuth}
                  history={useHistory}
                  location={useLocation}
                />
              );
            }}
          />

          <Route
            path="/:username/:alias2"
            exact
            component={({ match }) => {
              return (
                <CoLanding
                  match={match}
                  handleAuth={handleAuth}
                  history={useHistory}
                  location={useLocation}
                />
              );
            }}
          />

          {/* <Route
            render={props => (
              <NotFound
                {...props}
                handleAuth={handleAuth}
                history={useHistory}
                location={useLocation}
              />
            )}
          /> */}
        </Switch>
      </Suspense>
    </Router>
  );
}
