import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from 'history';
import "./styles/index.css";
import * as serviceWorker from "./serviceWorker";
import Router from "./components/Router";
import "./i18n";

import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
import { Offline as OfflineIntegration } from "@sentry/integrations";
import SentryRRWeb from "@sentry/rrweb";
import { version } from '../package.json';

const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_STAGE,
  release: version,
  integrations: [
    new Integrations.BrowserTracing(),
    new OfflineIntegration(
    {
      maxStoredEvents: 100
    }),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new SentryRRWeb()
  ],
  normalizeDepth: 10,
  tracesSampleRate: 1
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Router history={history}/>
  </Sentry.ErrorBoundary>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
