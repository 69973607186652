import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import "../styles/App.css";

import Nav from "../components/Nav";
import Footer from "../components/Footer";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Merriweather Sans",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  palette: {
    primary: {
      light: "#147bc9",
      main: "#147bc9",
      dark: "#054d85",
      contrastText: "#fff"
    },
    secondary: {
      light: "#147bc9",
      main: "#147bc9",
      dark: "#326822",
      contrastText: "#fff"
    }
  },
  AppBar: {
    boxShadow: "none"
  }
});

class Master extends React.Component {
  render() {
    // this.props.handleAuth.getSession();

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Nav
            handleAuth={this.props.handleAuth}
            history={this.props.useHistory}
            location={this.props.useLocation}
          ></Nav>
          {this.props.children}
          <Footer></Footer>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Master;
