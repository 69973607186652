import React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Translation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import { Container } from "@material-ui/core";

import NavMobile from "./NavMobile";

import logo from "../imgs/homewise-for-partners.png";
import logoMobile from "../imgs/hfp-logo-m.png";

const styles = (theme) => ({
  appBar: {
    padding: "0px",
    boxShadow: "none",
    background: "rgba(255,255,255,.85)",
  },
  toolBar: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    minHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "42px",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      display: `none`,
    },
  },
  logoMobile: {
    display: `none`,
    [theme.breakpoints.down("sm")]: {
      display: `block`,
    },
  },
  logoMask: {
    paddingTop: `3px`,
    width: `18px`,
    overflow: `hidden`,
  },
  logoNoMask: {
    paddingTop: `3px`,
    width: `211px`,
  },
  button: {
    fontSize: `.8rem`,
    textTransform: `none`,
    margin: `0 .5rem`,
  },
});

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullLogo: false,
    };
  }

  // componentDidMount() {
  //   window.addEventListener("scroll", this.handleScroll);
  // }

  handleScroll = () => {
    let html = document.documentElement,
      value = html.scrollTop;
    if (value >= 200) {
      this.setState({
        fullLogo: true,
      });
    } else {
      this.setState({
        fullLogo: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <AppBar className={classes.appBar}>
        <Container>
          <Toolbar className={classes.toolBar}>
            <Link href="./" style={{ display: `flex` }}>
              <img
                className={classes.logo}
                src={logo}
                alt="Homewise"
                height="24"
              />

              <img
                className={classes.logoMobile}
                src={logoMobile}
                alt="Homewise"
                width="85"
                height="23.5"
              />
            </Link>

            {this.props.handleAuth.isAuthenticated && (
              <div
                style={{
                  width: `60%`,
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `flex-end`,
                }}
              >
                <Hidden smDown>
                  <Button
                    className={classes.button}
                    color="primary"
                    href="./dashboard"
                  >
                    Dashboard
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    href="./contact"
                  >
                    Contact
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => {
                      this.props.handleAuth.signout(() => {
                        window.location.reload();
                      });
                    }}
                  >
                    Sign Out
                  </Button>
                </Hidden>
                <Hidden mdUp>
                  <NavMobile handleAuth={this.props.handleAuth} />
                </Hidden>
              </div>
            )}
            {!this.props.handleAuth.isAuthenticated && (
              <div
                style={{
                  width: `60%`,
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `flex-end`,
                }}
              >
                <Button
                  className={classes.button}
                  type="button"
                  color="primary"
                  href="./join"
                >
                  <Translation>
                    {(t) => <span>{t("common:join.cta")}</span>}
                  </Translation>
                </Button>
                <Button
                  className={classes.button}
                  type="button"
                  color="primary"
                  href="./sign_in"
                >
                  <Translation>
                    {(t) => <span>{t("landing:ctaSignIn")}</span>}
                  </Translation>
                </Button>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withRouter(withStyles(styles)(Nav));
