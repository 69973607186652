import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Master from "../../templates/master";

const styles = theme => ({
  margin: {
    margin: `10px`
  },
  hero: {
    height: `calc(100vh - 102px)`,
    padding: `10px 0px 0px`,
    [theme.breakpoints.up("md")]: {
      padding: `100px 0px 0px`,
      height: `calc(100vh - 192px)`
    },
    display: `flex`,
    overflow: `hidden`
  }
});

class Loading extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Master color="blue" handleAuth={this.props.handleAuth}>
        <main>
          <section className={classes.hero}>
            <Container maxWidth="md">
              <Grid container justify="center">
                <Grid item xs={12} sm={8}></Grid>
              </Grid>
            </Container>
          </section>
        </main>
      </Master>
    );
  }
}

export default withStyles(styles)(Loading);
